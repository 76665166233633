import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="support"
// You must attach this controller on the element you intend to focus on.
export default class SupportController extends Controller<HTMLElement> {
  static targets: string[] = ['input']

  declare readonly hasInputTarget: boolean
  declare readonly inputTarget: HTMLInputElement
  declare readonly inputTargets: HTMLInputElement[]

  mouseMoved() {
    if (this.hasInputTarget) {
      this.inputTarget.value = 'true'
    }
  }
}

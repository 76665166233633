import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="toggle"
export default class ToggleController extends Controller<HTMLElement> {
  static targets: string[] = ['toggle']

  declare readonly hasToggleTarget: boolean
  declare readonly toggleTarget: HTMLInputElement
  declare readonly toggleTargets: HTMLInputElement[]

  toggle() {
    this.toggleTarget.classList.toggle('hidden')
  }
}

import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="forms"
export default class FormsController extends Controller<HTMLFormElement> {
  static targets: string[] = ['submitButton']

  declare readonly hasSubmitButtonTarget: boolean
  declare readonly submitButtonTarget: HTMLElement
  declare readonly submitButtonTargets: HTMLElement[]

  requestSubmit(event: Event) {
    event.preventDefault()
    this.element.requestSubmit()
  }

  handleSubmitStart(event: Event) {
    if (!this.hasSubmitButtonTarget) return

    this.submitButtonTarget.classList.add('disabled')
    this.submitButtonTarget.setAttribute('disabled', 'disabled')
  }

  handleSubmitEnd(event: Event) {
    if (!this.hasSubmitButtonTarget) return

    this.submitButtonTarget.classList.remove('disabled')
    this.submitButtonTarget.removeAttribute('disabled')
  }
}

/* eslint-disable filenames/match-regex */
import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="service-worker"
export default class ServiceWorkerController extends Controller<HTMLElement> {
  async connect() {
    if (navigator.serviceWorker === null) {
      console.error('Service worker is not supported in this browser')
      return
    }

    let registration = await navigator.serviceWorker.getRegistration()
    if (registration) {
      return
    }

    if (navigator.serviceWorker.controller) {
      // If the service worker is already running, skip to state change
      this.stateChange()
    } else {
      // Register the service worker, and wait for it to become active
      registration = await navigator.serviceWorker.register(
        '/service-worker.js',
        {
          scope: './',
        }
      )
      console.log('Service worker change, registered the service worker')

      navigator.serviceWorker.addEventListener(
        'controllerchange',
        this.controllerChange.bind(this)
      )
    }
  }

  controllerChange(event: Event) {
    if (navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.addEventListener(
        'statechange',
        this.stateChange.bind(this)
      )
    }
  }

  stateChange() {
    // perform any visual manipulations here
  }
}

import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="blankslate"
export default class BlankslateController extends Controller<HTMLElement> {
  static targets: string[] = ['blankslate', 'container', 'item']

  declare readonly hasBlankslateTarget: boolean
  declare readonly blankslateTarget: HTMLInputElement
  declare readonly blankslateTargets: HTMLInputElement[]

  declare readonly hasItemTarget: boolean
  declare readonly itemTarget: HTMLInputElement
  declare readonly itemTargets: HTMLInputElement[]

  declare readonly hasContainerTarget: boolean
  declare readonly containerTarget: HTMLInputElement
  declare readonly containerTargets: HTMLInputElement[]

  itemTargetConnected(_target: Element) {
    if (this.hasBlankslateTarget && this.itemTargets.length !== 0) {
      if (this.hasContainerTarget) {
        this.containerTarget.classList.remove('hidden')
      }

      this.blankslateTarget.classList.add('hidden')
    }
  }

  itemTargetDisconnected(_target: Element) {
    if (this.hasBlankslateTarget && this.itemTargets.length === 0) {
      if (this.hasContainerTarget) {
        this.containerTarget.classList.add('hidden')
      }

      this.blankslateTarget.classList.remove('hidden')
    }
  }
}

import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs"
export default class TabsController extends Controller<HTMLElement> {
  static targets: string[] = ['tab', 'content']

  declare readonly hasTabTarget: boolean
  declare readonly tabTarget: HTMLElement
  declare readonly tabTargets: HTMLElement[]

  declare readonly hasContentTarget: boolean
  declare readonly contentTarget: HTMLElement
  declare readonly contentTargets: HTMLElement[]

  select(event: Event) {
    const selectedTab = event.currentTarget as HTMLElement
    const contentTargetId = selectedTab.getAttribute('aria-controls')

    // Update tab states
    for (const tab of this.tabTargets) {
      if (tab.id !== selectedTab.id) {
        tab.classList.remove('active')
        tab.ariaSelected = 'false'
      }
    }
    selectedTab.classList.add('active')
    selectedTab.ariaSelected = 'true'

    for (const content of this.contentTargets) {
      if (content.id === contentTargetId) {
        content.classList.remove('hidden')
      } else {
        content.classList.add('hidden')
      }
    }
  }
}

import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

// Connects to data-controller="web-push-subscriptions"
// Users cannot subscribe to web push notifications unless the origin is same-origin, no cross-origin subscriptions
// allowed.
export default class WebPushSubscriptionsController extends Controller<HTMLElement> {
  static targets: string[] = [
    'subscription',
    'supported',
    'unsupported',
    'subscribePrompt',
  ]

  declare readonly hasSubscribePromptTarget: boolean
  declare readonly subscribePromptTarget: HTMLElement
  declare readonly subscribePromptTargets: HTMLElement[]

  declare readonly hasSubscriptionTarget: boolean
  declare readonly subscriptionTarget: HTMLInputElement
  declare readonly subscriptionTargets: HTMLInputElement[]

  // When the current browser does support web push, the supportedTarget
  // is shown and the unsupportedTarget is.
  declare readonly hasSupportedTarget: boolean
  declare readonly supportedTarget: HTMLInputElement
  declare readonly supportedTargets: HTMLInputElement[]

  // When the current browser does not support web push, the subscriptionTarget
  // is hidden and the unsupportedTarget is.
  declare readonly hasUnsupportedTarget: boolean
  declare readonly unsupportedTarget: HTMLInputElement
  declare readonly unsupportedTargets: HTMLInputElement[]

  static values = {
    applicationServerKey: String,
    registerUrl: String,
  }

  declare applicationServerKeyValue: string
  declare registerUrlValue: string
  declare readonly hasApplicationServerKeyValue: boolean
  declare readonly hasRegisterUrlValue: boolean

  subscriptionTargetConnected() {
    this.detectCurrentSubscription()
  }

  async connect() {
    this.detectCurrentSubscription()
  }

  async detectCurrentSubscription() {
    if (!this.hasSubscriptionTarget && !this.hasSubscribePromptTarget) {
      return
    }

    if (
      'serviceWorker' in navigator &&
      'PushManager' in window &&
      'caches' in window
    ) {
      // The browser supports PWAs
      if (this.hasSupportedTarget) {
        this.supportedTarget.classList.remove('hidden')
      }

      if (this.hasUnsupportedTarget) {
        this.unsupportedTarget.classList.add('hidden')
      }
    } else {
      // The browser does not support PWAs
      if (this.hasUnsupportedTarget) {
        this.unsupportedTarget.classList.remove('hidden')
      }

      if (this.hasSupportedTarget) {
        this.supportedTarget.classList.add('hidden')
      }

      return null
    }

    const subscription = await this.getSubscription()

    // The browser supports PWAs and the user is already subscribed
    if (this.hasSubscribePromptTarget) {
      for (const subscribePrompt of this.subscribePromptTargets) {
        if (subscription) {
          subscribePrompt.classList.add('hidden')
        } else {
          subscribePrompt.classList.remove('hidden')
        }
      }
    }

    for (const subscriptionTarget of this.subscriptionTargets) {
      if (subscriptionTarget.dataset.endpoint === subscription?.endpoint) {
        subscriptionTarget.classList.remove('hidden')
      }
    }
  }

  async subscribe() {
    if (!this.hasRegisterUrlValue || !this.hasApplicationServerKeyValue) {
      return
    }

    const permission = await window.Notification.requestPermission()
    console.debug(
      `Notification.requestPermission() result: ${permission}`,
      window
    )
    if (permission !== 'granted') {
      return
    }

    const subscription = await this.requestSubscription()
    if (subscription) {
      const response = await post(this.registerUrlValue, {
        body: JSON.stringify({
          subscription,
        }),
        responseKind: 'turbo-stream',
      })
    }
  }

  async getSubscription(): Promise<PushSubscription | null> {
    if (!this.hasApplicationServerKeyValue) {
      return null
    }

    const registration = await navigator.serviceWorker.ready
    if (!('pushManager' in registration)) {
      return null
    }

    return registration.pushManager.getSubscription()
  }

  async requestSubscription(): Promise<PushSubscription | null> {
    if (!this.hasApplicationServerKeyValue) {
      return null
    }

    const registration = await navigator.serviceWorker.ready
    if (!('pushManager' in registration)) {
      return null
    }

    const pushManager = registration.pushManager
    const existingSubscription = await pushManager.getSubscription()
    if (existingSubscription) {
      return existingSubscription
    }

    return pushManager.subscribe({
      // Both the WebKit open source project and Apple treat privacy as a fundamental human right. As with other
      // privileged features of the web platform, requesting a push subscription requires an explicit user gesture.
      // It also requires you set the userVisibleOnly flag to true, and fulfill that promise by always showing a
      // notification in response to a push message.
      //
      // See https://webkit.org/blog/12945/meet-web-push/
      userVisibleOnly: true,
      applicationServerKey: this.urlBase64ToUint8Array(
        this.applicationServerKeyValue
      ),
    })
  }

  // from https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
  urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')

    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }
}
